export default {
    path: '/',
    component: () => import(/* webpackChunkName: "home-login" */ '../../layouts/Login.vue'),
    children: [
        {path: 'login', name: 'login', component: () => import(/* webpackChunkName: "login" */ './Login.vue')},
        {path: 'register', name: 'register', component: () => import(/* webpackChunkName: "register" */ './Register.vue')},
        {path: 'logout', name: 'logout', component: () => import(/* webpackChunkName: "logout" */ './Logout.vue')},
        /* {path: 'password-reset', name: 'password-reset', component: () => import('./PasswordReset.vue')},
        {path: 'verification', name: 'verification', component: () => import('./Verification.vue'), props: true},
        {path: 'reset', name: 'verify-password-reset', component: () => import('./VerifyPasswordReset.vue'),}, */
    ],
    meta: {},
};
