import _Vue from 'vue'

declare module 'vue/types/vue' {
    // 3. Declare augmentation for Vue
    interface Vue {
        /* $copy: <T>(element: T) => T
        $deepCopy: <T>(element: T) => T
        $groupBy: <T>(elements: Array<T>, key: (item: any) => string | number) => { [key: string]: T[] }
        $loadModule: (module: string) => boolean
        $escapeRegex: (val: string) => string,
        $mergeArrays: <T>(array1: null | undefined | T[], array2: null | undefined | T[]) => T[]
        $sortArrayByArray: <T, M>(arr: T[], sortByArr: M[], callback: (sortArrEl: M, arrEl: T) => boolean) => T[]
        $n2br: (text: string | undefined) => string, */
        $updateThemeColor: (color: any) => '',
    }
}

export default {
    install(Vue: typeof _Vue, options: { store: any } = {store: null}) {

        Vue.prototype.$updateThemeColor = (color: any) => {
            //console.log(color)
            //@ts-ignore
            return document.head.querySelector('meta[name="theme-color"]').content = color || '#fff'
        }
    }
}
