import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

import Account from '@/views/account/routes'

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "home" */ '../layouts/Default.vue'),
        children: [
            {
                path: '',
                name: 'lists',
                component: () => import(/* webpackChunkName: "lists" */ '../views/Lists.vue')
            },
            {
                path: '/list/:listId',
                name: 'items',
                component: () => import(/* webpackChunkName: "items" */ '../views/Items.vue'),
                props: true
            },
            {
                path: '/category/:categoryId',
                name: 'hints',
                component: () => import(/* webpackChunkName: "hints" */ '../views/Hints.vue')
            },
            {
                path: '/categories',
                name: 'categories',
                component: () => import(/* webpackChunkName: "categories" */ '../views/Categories.vue')
            },
            {
                path: '/users',
                name: 'users',
                component: () => import(/* webpackChunkName: "categories" */ '../views/Users.vue')
            }
        ],
        meta: {
            requiresAuth: true,
        }
    },
    Account
]

const router = new VueRouter({
    routes
})

export default router
