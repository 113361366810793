import feathersClient, {
    makeServicePlugin,
    BaseModel
} from '../feathers-client.js'
import { FeathersVuex } from '../feathers-client.js'

class Recommendation extends BaseModel {
    // eslint-disable-next-line no-useless-constructor
    constructor (data, options) {
        super(data, options)
    }

    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = 'Recommendation'

    // Define default properties here
    static instanceDefaults () {
        return {
            _id: '',
            average: '',
            lastBuy: '',
            count: '',
            item: ''
        }
    }
}

const servicePath = 'recommendations'
const servicePlugin = makeServicePlugin({
    Model: Recommendation,
    service: feathersClient.service(servicePath),
    servicePath,
    skipRequestIfExists: true,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    after: {
        all: [
            async (context) => {
                const { service, result, app } = context

                context.result = await Promise.all(result.map(async (recommendation, index) => {
                    let item = await service.FeathersVuexModel.store.dispatch('items/get', recommendation._id).catch(e=>{})
                    if (!item) return

                    return { ...recommendation, ...item }
                }))
            }
        ],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: []
    }
})

export default servicePlugin
